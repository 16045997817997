import React from "react"
import { graphql } from "gatsby"
import { createUseStyles } from "react-jss"
import parse from "html-react-parser"
import Heading from "../../components/content/heading"
import Section from "../../components/containers/section"
import SectionContent from "../../components/containers/section-content"
import Rows from "../../components/grid/rows"
import Columns from "../../components/grid/columns"
import { Content } from "../../components/grid"
import Main from "../../components/layout/main"
import Seo from "../../components/seo/seo"
import Copy from "../../components/content/copy"
import CtaBox from "../../components/containers/cta-box"
import SectionBackground from "@src/components/containers/section-background"

const useStyles = createUseStyles({
  flexColumns: {
    "@media (max-width: 769px)": {
      display: "flex",
      flexDirection: "column-reverse",
    },
  },
  smallerText: {
    "& > p": {
      fontSize: 18,
    },
  },
  tinyText: {
    "& > p": {
      fontSize: 14,
      lineHeight: "28px",
    },
  },
  overFlow: {
    overflow: "visible !important",
  },
})

function TermsPageUSA({ data, location }) {
  const {
    Header,
    seo,
    Content: content,
    UsTerms,
    IntroText,
    CtaBanner,
  } = data.strapiPageTerms
  const cx = useStyles()

  const seoObj = {
    title: seo.metaTitle,
    description: seo.metaDesc,
    image: seo.shareImage.localFile.publicURL,
    imageAlt: seo.shareImage.alternativeText,
    url: location && location.pathname,
  }

  return (
    <Main>
      <Seo customData={seoObj} />
      <header>
        <Section>
          <SectionBackground>
            <div className="hero-image--careers" />
          </SectionBackground>
          <SectionContent hero>
            <Rows gap="none" className="mb-md">
              <Columns count="1">
                <Content gap="md">
                  <Heading className="mt-xl" level={1}>
                    🌎 USA {Header.Header}
                  </Heading>
                  <Copy
                    contentToParse={Header.Content}
                    className={cx.smallerText}
                  />
                  <Copy contentToParse={IntroText.Content} />
                </Content>
              </Columns>
            </Rows>
          </SectionContent>
        </Section>
      </header>

      <Section>
        <SectionContent paddingTop="none">
          <Rows gap="xxl" className="mb-xxl">
            {UsTerms.map(contentSection => (
              <Columns count="2" className={cx.flexColumns}>
                <Content>
                  <Heading
                    level={3}
                    className="text-gradient text-gradient__purple-blue-horz"
                  >
                    TL:DR
                  </Heading>
                  <Copy contentToParse={contentSection.Summary} />
                </Content>
                <Content>
                  <Heading level={3}>{parse(contentSection.Heading)}</Heading>
                  <Rows gap="xs" className="text--xxs">
                    <Copy
                      contentToParse={contentSection.Content}
                      className={cx.tinyText}
                    />
                  </Rows>
                </Content>
              </Columns>
            ))}
          </Rows>
          <Section className={`${cx.overFlow} mob-pt-xl`}>
            <CtaBox data={CtaBanner} />
          </Section>
        </SectionContent>
      </Section>
    </Main>
  )
}

export const query = graphql`
  query TermsPageUSAQuery {
    strapiPageTerms {
      Header {
        Content
        Header
      }
      IntroText {
        Content
      }
      UsTerms {
        Content
        Heading
        Summary
        id
      }
      seo {
        metaDesc
        metaTitle
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      CtaBanner {
        ButtonURL
        ButtonText
        Heading
        OpenInNewTab
      }
    }
  }
`

export default TermsPageUSA
